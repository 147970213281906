import './userDashboard.scss'

import { NavLink, useNavigate } from 'react-router-dom'

import Logo from '../../../assets/logo-new.png'
import LogoWhite from '../../../assets/logo-white.png'
import supportIcon from '../../../assets/headphones.png'
import hamburgerIcon from '../../../assets/hamburger.png'

import { BiRupee } from 'react-icons/bi'
import { BsShareFill } from 'react-icons/bs'
import { GrClose } from 'react-icons/gr'
import { MdFileDownload } from 'react-icons/md'

import { useEffect, useState } from 'react'
import { useAuth } from '../../auth'

import axios from 'axios'
import { env } from '../../../environment/environment'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

const UserDashboard = () => {

    const [username, setUsername] = useState('');
    const [totalLoanAmt, setTotalLoanAmt] = useState('');
    const [tenure, setTenure] = useState('');
    const [nextInstalment, setNextInstalment] = useState('');
    const [nextInstalmentDate, setNextInstalmentDate] = useState('');
    const [disbursedDate, setDisbursedDate] = useState('');
    const [totalDueAmount, setTotalDueAmount] = useState('');
    const [EMIsPaid, setEMIsPaid] = useState('');
    const [userData, SetUserData] = useState('');
    const [loanData, SetLoanData] = useState('');
    const [tab, setTab] = useState('personal');
    const [photograpUrl, setphotograpUrl] = useState('');
    const [bankstatementUrl, setbankstatementUrl] = useState('');
    const [salaryProofUrl, setSalarySlipUrl] = useState('');
    const [notificationData, setNotificationData] = useState('');

    // let token = localStorage.getItem('access_token');
    // const config = {
    //     headers: { Authorization: `Bearer ${token}` }
    // };
    //
    const token = localStorage.getItem('authToken');

    let userId = localStorage.getItem("userId");
    useEffect(() => {
        if (userId === '' || userId === 'null' || userId === null) {
            handleLogout()
        }
        axios
            .get(env.api_Url + "getLoanDetailByUserId?userId=" + userId + '&type=all')
            .then((response) => {
                // if(response.data.status == "200"){

                SetLoanData(response.data)
                // }
            }).catch(error => {
                console.log(error);
            });
        axios
            .get(env.api_Url + "userDetails/userDetailById?userId=" + userId)
            .then((response) => {
                // if(response.data.status == "200"){

                SetUserData(response.data)
                // }
            }).catch(error => {
                console.log(error);
            });
        axios.get(`${env.api_Url}getUserDocumentDetailByUserId?userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response.data)
                const { photographUrl, salaryProofUrl, bankStatementUrl } = response.data.documentList
                setphotograpUrl(photographUrl)
                setSalarySlipUrl(salaryProofUrl)
                setbankstatementUrl(bankStatementUrl)
            })
            .catch(error => {
                console.error('Error fetching bank details:', error)
            })
        axios.get(`${env.api_Url}mobile/getNotificationByUserId?userId=${userId}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log(response.data)
                setNotificationData(response.data)


            })
            .catch(error => {
                console.error('Error fetching bank details:', error)
            })

    }, [])


    // function fetchDashboardDetails(){
    // useEffect(()=>{
    //     axios
    //     .post(env.api_Url + "dashboard_detail", {},
    //         config)
    //     .then((response) => {
    //         let responseCode = response.status+"";
    //         if(responseCode[0] == '2'){
    //             let status = response.data.data[0];
    //             console.log(status)
    //             filterdate(status.loan_applied_create_date);
    //             setUsername(sanitizeName(status.username));
    //             setTotalLoanAmt(status.total_credit_amount);
    //             nextInstalmentDetails(status.emi_details)
    //             balanceDue(status.emi_details)
    //             setTenure(status.tenure)
    //         }
    //     }).catch(error => {
    //         console.log(error);
    //     });
    // }, [])
    // }

    function filterdate(loanDate) {
        let date = new Date(loanDate);
        let parts = [];
        parts.push(date.getDate()); parts.push(date.getMonth() + 1); parts.push(date.getFullYear());
        let hours = date.getHours();
        let meridiem = "AM";

        if (hours > 12) {
            meridiem = "PM";
            hours -= 12;
        }
        if (hours < 10) hours = "0" + hours;

        let time = hours + ":" + date.getMinutes() + " " + meridiem;
        date = parts.join("/");
        setDisbursedDate(date + ", " + time);
    }

    function nextInstalmentDetails(emis) {
        let paidMonths = 1;
        for (let i = 0; i < emis.length; i++) {
            if (emis[i].status == "PAID") paidMonths++;

            if (emis[i].status == "UNPAID") {
                setNextInstalment(emis[i].repayment_amount);
                let date = emis[i].repayment_date;
                date = date.split("-").reverse().join("/");
                setNextInstalmentDate(date);
                // setTotalDueAmount(emis[i].balance_due);
                break;
            }
        }
        setEMIsPaid(paidMonths);
    }

    function balanceDue(emis) {
        let balanceDue = 0;
        for (let i = 0; i < emis.length; i++) {
            if (emis[i].status == "UNPAID") {
                balanceDue += emis[i].balance_due;
            }
        }
        setTotalDueAmount(balanceDue);
    }

    function sanitizeName(name) {
        name = name.split("-")[0];
        let parts = name.split(" ");
        parts.forEach((part, idx) => {
            part = part.toLowerCase();
            let sanitized = part.charAt(0).toUpperCase() + part.slice(1);
            parts[idx] = sanitized;
        });
        // console.log(parts)
        return parts.join(" ");
    }

    const [menuState, setMenuState] = useState(false);

    function showMenu() {
        setMenuState(true);
    }
    function hideMenu() {
        setMenuState(false);
    }

    const auth = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {

        localStorage.clear();
        navigate('/')
    }

    // let userName = "Vikas Gupta."

    const getNotification = () => {
        setTab('notification')

    }
    return (
        <>
            {console.log(notificationData)}
            <main className='userDashboard'>
                <div className='dashboard'>
                    <img onClick={showMenu} className='hamburgerIcon' src={hamburgerIcon} alt="" />
                    <img className="header-logo" style={{ width: '130px',marginLeft:notificationData.count?'':'15px' }} src={Logo}></img>
                    <div className="help help-notification">
                        {/* Help
                    <img src={supportIcon} alt="" /> */}
                        {/* <NotificationsActiveIcon onClick={() => getNotification()} />{notificationData.count}
                         */}
                        {notificationData && notificationData.count ?
                            <div className="help">
                                {notificationData && notificationData.count ?
                                    <NotificationsActiveIcon
                                        className="notification-icon"
                                        onClick={() => getNotification()}
                                    />
                                    : ""}
                                {notificationData && notificationData.count > 0 && (
                                    <span className="notification-count">
                                        {notificationData.count}
                                    </span>
                                )}
                            </div>
                            : ""}
                    </div>
                 
                </div>
             <div className='border-bottom-head'></div>

                <p className='username'>Hi! {userData.firstName}.</p>
<p>Application Id: {localStorage.getItem('applicationId')}</p>
                <div style={{ display: 'flex' }}>
                    <button className='btn-tab' style={{ background: tab === 'personal' ? '#53b6ef' : '', color: tab === 'personal' ? '#fff' : '' }} onClick={() => setTab('personal')}>Personal</button>
                    <button className='btn-tab' style={{ background: tab === 'loan' ? '#53b6ef' : '', color: tab === 'loan' ? '#fff' : '' }} onClick={() => setTab('loan')}>Loan</button>
                    <button className='btn-tab' style={{ background: tab === 'bank' ? '#53b6ef' : '', color: tab === 'bank' ? '#fff' : '' }} onClick={() => setTab('bank')}>Bank</button>
                    {photograpUrl !== '' || bankstatementUrl !== '' || salaryProofUrl !== '' ? <button className='btn-tab' style={{ background: tab === 'docs' ? '#53b6ef' : '', color: tab === 'docs' ? '#fff' : '' }} onClick={() => setTab('docs')}>Documents</button> : ""}

                </div>
                {tab === 'personal' ?
                    <>
                        <p>Personal Details:</p>

                        <div className="currPlanCard animated slideInLeft">
                            <div className="">
                                <div className="">
                                    <span> {(userData.mobileNumber)}</span>
                                    <p>Mobile Number</p>
                                </div>
                                <div className="">
                                    <span>{userData.emailId} </span>
                                    <p>Email Id</p>
                                </div>
                            </div>
                            <div className="middle">
                                <div className="DueAmt">
                                    <span> {userData.dateOfBirth}</span>
                                    <p>Date Of Birth</p>
                                </div>

                            </div>

                        </div>
                    </>
                    : ""}
                {tab === 'loan' ?
                    <>
                        <p style={{ marginTop: '20px' }}>Your current credit plan:</p>

                        <div className="currPlanCard animated slideInLeft">
                            <div className="upper">
                                <div className="loanAmt">
                                    <span><BiRupee /> {(loanData.loanAmount)}</span>
                                    <p>Total credit amount</p>
                                </div>
                                <div className="tenure">
                                    <span>{loanData.loanEmi} months</span>
                                    <p>Tenure</p>
                                </div>
                            </div>
                            <div className="middle">
                                <div className="DueAmt">
                                    <span> {loanData.applicationId}</span>
                                    <p>Application Id</p>
                                </div>
                                <div className="nextInst">
                                    <span>{loanData.loanApplyDate}</span>
                                    <p>Date</p>
                                </div>
                            </div>
                            <div className="middle">
                                <div className="DueAmt">
                                    <span> {loanData.applicationStatus}</span>
                                    <p>Application Status</p>
                                </div>

                            </div>


                        </div>
                    </>
                    : ""}
                {tab === 'bank' ?
                    <>
                        <p>Bank Details:</p>

                        <div className="currPlanCard animated slideInLeft">
                            <div className="upper">
                                <div className="loanAmt">
                                    <span> {(userData.accountNumber)}</span>
                                    <p>Account Number</p>
                                </div>
                                <div className="tenure">
                                    <span>{userData.bankName} </span>
                                    <p>Bank Name</p>
                                </div>
                            </div>
                            <div className="middle">
                                <div className="DueAmt">
                                    <span> {userData.branch}</span>
                                    <p>Branch</p>
                                </div>
                                <div className="DueAmt">
                                    <span> {userData.ifscCode}</span>
                                    <p>IFSC</p>
                                </div>
                            </div>

                        </div>
                    </>
                    : ""}
                {tab === 'docs' ?
                    <>
                        <p>Documents:</p>

                        <div className="currPlanCard animated slideInLeft">

                            {photograpUrl !== '' ?
                                <>
                                    <p>Selfie</p>
                                    <img style={{ maxWidth: '200px' }} src={photograpUrl} />
                                </>
                                : ""}
                            {bankstatementUrl !== '' ?
                                <>
                                    <p>Bank Statement</p>
                                    <iframe width={'100%'} height={'200px'} src={bankstatementUrl} />
                                </>
                                : ""}
                            {salaryProofUrl !== '' ?
                                <>
                                    <p>Salary Slip</p>
                                    <iframe width={'100%'} height={'200px'} src={salaryProofUrl} />
                                </>
                                : ""}

                        </div>
                    </>
                    : ""}
                {tab === 'notification' ?
                    <>
                        <p>Notification</p>

                        {notificationData.notificationPOList.length > 0 ? (
                            notificationData.notificationPOList.map(notification => (
                                <div className="currPlanCard animated slideInLeft">
                                    <div key={notification.id} className="notification-card">
                                        <div className="notification-header">
                                            <span className="notification-type">{notification.notificationType}</span>
                                            <span className={`notification-mark ${notification.notificationMark}`}>
                                                {/* {notification.notificationMark} */}
                                            </span>
                                        </div>
                                        <div className="notification-body">
                                            <p>{notification.notificationMessage}</p>
                                            <br />
                                            <p className="notification-date">{notification.notificationDate}</p>
                                        </div>
                                        {/* Conditionally render image if available */}
                                        {notification.imageUrl && (
                                            <div className="notification-image">
                                                <img src={notification.imageUrl} alt="Notification" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No notifications available</p>
                        )}


                    </>
                    : ""}


                <div className={menuState ? "menu show" : "menu"}>
                    <div className="top">
                        <div style={{ padding: '5px', background: '#fff', borderRadius: '5px' }}><img src={Logo} width={'130px'} alt="" /></div>
                        <GrClose onClick={hideMenu} className='close' />
                    </div>

                    <nav>
                        {/* <NavLink to=''>Dashboard</NavLink>
                    <NavLink to=''>Pay Instalment</NavLink>
                    <NavLink to=''>Contact Support</NavLink> */}

                        <ul>
                            <li className='active'>Dashboard</li>
                            {/* <li >Pay Instalment</li>
                        <li>Contact Support</li> */}
                            <li style={{ color: '#fff' }} onClick={handleLogout}>Logout</li>
                        </ul>

                    </nav>
                </div>

            </main>


        </>
    )
}

const TenureBar = ({ paid, total }) => {
    // const [Paid, setPaid] = useState(0);
    return (
        <div className="tenureBar">
            <div className={paid > 0 ? 'bar f' : "bar"}></div>
            <div className={paid > 1 ? 'bar f' : "bar"}></div>
            <div className={paid > 2 ? 'bar f' : "bar"}></div>

            {total > 3 &&
                <>
                    <div className={paid > 3 ? 'bar f' : "bar"}></div>
                    <div className={paid > 4 ? 'bar f' : "bar"}></div>
                    <div className={paid > 5 ? 'bar f' : "bar"}></div>
                </>
            }


        </div>
    )
}
const TrnsCard = ({ status }) => {
    let trxnDate = "12/05/22";
    let trxnTime = "04:55";
    let trxnStatus = status;

    let trxnID = "TRSC675423AA";
    let trxnAmt = "30,000";

    let statusStyle = {
        color: "#1C8769",
        background: "#CFE5DF"
    };

    if (status == "Failed") {
        statusStyle.color = "#DF4759";
        statusStyle.background = "#FFE8EA";
    }
    return (
        <div className="transxCard">
            <div className="head">
                <div className="timestamp">{trxnDate} {trxnTime} <span className='status' style={statusStyle}>{trxnStatus}</span></div>
                <span className='share-btn'>Share <BsShareFill /></span>
            </div>
            <div className="transaction">
                <div className="trxnID">
                    <span>{trxnID}</span>
                    <p>Transaction ID</p>
                </div>
                <div className="trxnAmt">
                    <span>{trxnAmt}</span>
                    <p>Amount</p>
                </div>
            </div>
        </div>
    )
}

export default UserDashboard